.card{
    max-width: 450px;
    height: 565px;
    border-radius: 10px !important;
    padding-top: 50px !important;
    transition: 0.2s ease-out;
    border: 2px solid #c3c6ce !important;
}
.card:hover{
    border-color: #008bf8 !important;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
    scale: 1.02;
}
.title{
    text-align: center;
    font-family: 'Playfair Display', serif !important;
    font-weight: bold !important;
}
.avatar-box{
    display: flex;
    justify-content: center;
    align-items: center;

}
.price-box{
    text-align: center;
    margin-top: 30px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    font-family: 'Poppins', sans-serif !important;
}
.info-txt{
    text-align: center;
    font-size: 15px !important;
}
.info-txt-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.tick-icon{
    color: green;
    font-size: 24px;
    margin-right: 5px;
}
.btn-box{
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-btn{
    background-color: #24A789 !important;
    color: white;
    width: 400px !important;
}
.modal-btn:hover{
    background-color: #0d9071 !important;
}