  .ribbon {
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 1;
    overflow: hidden;
    width: 200px; height: 200px; 
    text-align: right;
    border: 1px black;
 }
 .ribbon span {
    font-size: 14px;
    color: black; 
    text-shadow: 1px 1px 5px #d5d2d2;
    text-align: center;
    font-weight: 600;
    line-height: 25px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 150px; display: block;
    background: #FFFF00;
    background: linear-gradient(#FDDA0D 0%, #FFD700 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 31px;
    left: -32px
 }

 .ribbon span::before {
    content: '';
    position: absolute; 
    left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #FFD700;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #FFD700;
 }
 .ribbon span::after {
    content: '';
    position: absolute; 
    right: 0%; top: 100%;
    z-index: -1;
    border-right: 3px solid #FFD700;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #FFD700;
 }
 .primary-button{
   display: flex;
   justify-content: center;
    align-items: center;
   position: absolute;
   bottom: 65px;
   left: 0px;
   right:0px;
 }
.secondary-button{
   display: flex;
   justify-content: center;
    align-items: center;
   position: absolute;
   bottom: 10px;
   left: 0px;
   right:0px;
}